.react-datepicker-wrapper {
    display: block !important;
    z-index: 1000;
}
.react-datepicker {
    font-family: "Montserrat" !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #4e5e9e !important;
}
.react-datepicker__day--selected {
    background-color: #4e5e9e !important;
}
.react-datepicker__header {
    background-color: #4e5e9e !important;
}
.react-datepicker__header > div,
.react-datepicker__day-name {
    color: white !important;
}
.react-datepicker__day--in-range {
    background-color: #4e5e9ead !important;
}
.react-datepicker__triangle {
    border-bottom-color: #4e5e9ead !important;
}
.highlight {
    background-color: #4e5e9e45 !important;
}

.react-datepicker__close-icon::after {
    background-color: #4e5e9e !important;
    height: 18px !important;
    width: 18px !important;
    padding: 0px !important;
    font-size: 16px !important;
}

.InfiniteCarouselDotActiveIcon {
    background-color: #4e5e9e !important;
}
.InfiniteCarouselDots {
    display: flex;
    flex-direction: row;
    max-width: 50vw;
    overflow-x: auto;
    height: 30px;
}
.InfiniteCarouselArrow:focus {
    outline: 0;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.InfiniteCarouselDots::-webkit-scrollbar {
    display: none;
}
.InfiniteCarouselDots {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
