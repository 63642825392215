/*! `Custom` Bootstrap 4 theme */
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,700);
$font-family-base:Montserrat;
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,700);
$headings-font-family:Montserrat;

$primary:#4e5e9e;

$secondary: #54C4E6;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';